.pre-surgery-container {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
  overflow: auto;
  margin-top: 1.5em;
}

.completed-survey-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.check-circle-container {
  color: #00b750;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.check-circle-container svg {
  width: 90px;
  height: 90px;
}

.link-button-container .btn {
  color: #ffffff;
  border-radius: 6px;
  background-color: var(--primary-color);
}

.title {
  font-size: 42px;
  text-align: center;
  color: #2c2c2c;
  margin-bottom: 15px;
}

.info {
  font-size: 18px;
  color: #2c2c2c;
}

.bold-text {
  font-weight: bold;
}
