.top-navbar {
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.27);
  background-color: #ffffff;
  padding: 0 0.5rem;
}

.top-navbar .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  line-height: 2.86;
  color: #2c2c2c;
}

.navbar-nav .dashboard-link.nav-link {
  padding-right: 2rem;
  padding-left: 2rem;
}

.navbar-nav .programs-link.nav-link {
  padding-right: 2rem;
  padding-left: 2rem;
}

.navbar-nav .notification-nav-lg.nav-link {
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}

.top-navbar .navbar-nav .notification-nav-lg .nav-link {
  padding-left: 2rem;
}

.notification-nav-lg a {
  padding-right: 2rem;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.top-navbar .avatar-container {
  margin-top: 0.75em;
}

@media (min-width: 991px) {
  .top-navbar .notification-nav-sm {
    display: none;
  }
  .top-navbar .logout-link {
    display: none;
  }
  .top-navbar .nav-link.active {
    font-weight: "600";
    border-bottom: solid 5px var(--primary-color);
  }
  .top-navbar .profile-link {
    display: none;
  }
}

@media (max-width: 991px) {
  .top-navbar {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
  .top-navbar .nav-link {
    margin-top: -0.2em;
    margin-bottom: -0.5em;
    padding-bottom: 0;
    height: 3.5em;
  }
  .top-navbar .avatar-container {
    margin-top: 1em;
    padding-top: 10px;
    padding-bottom: 2.5px;
  }
  .top-navbar .notification-nav-lg {
    display: none;
  }
  .top-navbar .sm-notification-icon-number {
    top: -10px;
    left: 8px;
  }
  .top-navbar .profile-link .nav-link {
    padding-bottom: 50px;
  }
  .top-navbar .notification-icon-number {
    display: none;
  }
  .top-navbar .logout-link {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .top-navbar .nav-link.active {
    border-bottom: none;
    border-left: solid 5px var(--primary-color);
  }
  .top-navbar .logout-link.nav-link.active {
    border-bottom: none;
    border-left: none;
  }
}

.notification-nav-lg .dropdown-toggle:after {
  content: none;
}

.notification-nav-lg {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 70px;
  padding-left: 25px;
}

.notification-nav-lg .dropdown-menu.show {
  position: absolute;
  left: -270px;
  top: 57.5px;
  width: 320px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.14);
  background-color: #f06d21;
  border: none;
}

.notification-orange-text {
  margin: 9px 0 0;

  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.notification-orange-number-text {
  margin: 9px 0 0;

  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  padding-right: 5px;
}

.notification-orange-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-orange-notification-text {
  opacity: 0.9;

  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-orange-notification-text-container {
  padding-bottom: 10px;
}

.white-notification-dropdown-container {
  width: 100%;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-notification-dropdown-container:hover,
.white-notification-dropdown-container .dropdown-item:hover {
  background-color: #ffffff;
}

.white-notification-dropdown-container .dropdown-item:hover {
  background-color: none;
}

.white-notification-dropdown-container .dropdown-item {
  padding: 0;
}

.white-notification-dropdown-container .dropdown-item a:hover {
  background-color: #f5f5f5;
}

.avatar-dropdown-container .dropdown-item:hover {
  background-color: #f5f5f5;
}

.logout-dd-container.dropdown-item:hover {
  background-color: #f5f5f5;
}

.notification-white-section-bold-text {

  font-size: 14px;
  font-weight: 600;
  color: #2c2c2c;
}

.notification-white-section-small-text {

  font-size: 12px;
  color: #2c2c2c;
}

.notification-white-section-time-text {

  font-size: 10px;
  text-align: right;
  color: #757575;
}

.notification-dd-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.notification-middle-text-row {
  margin-bottom: -7px;
}

.notification-middle-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification-container.dropdown-item:hover {
  background-color: #f06d21;
}

.white-notification-dropdown-container .dropdown-item:hover {
  background-color: none;
}

.white-notification-dropdown-container .dropdown-item:active {
  background-color: #ffffff;
}

/* Notification Small Screen */

.notification-nav-sm .dropdown-toggle:after {
  content: none;
}

.notification-nav-sm {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-nav-sm .dropdown-menu.show {
  position: absolute;
  top: 47.5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.14);
  background-color: #f06d21;
  border: none;
}

.notification-icon-number {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 4px 0;
  background-color: #f06d21;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 95px;
  top: 10px;
}

.logout-container.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f5f5f5;
}

.avatar-dropdown-container .dropdown-menu.show {
  position: absolute;
  width: 200px;
    left: -150px;
  top: 51.5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  border: none;
}

.avatar-dropdown-container {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
}

.avatar-dropdown-container a.dropdown-toggle.nav-link {
  padding-left: 1rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 2rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: unset;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-toggle:after {
  content: none;
}

.avatar-dropdown-container .dropdown-item.active,
.dropdown-item:active {
  background-color: #ffffff;
}

.nav-dropdown-text {
  font-size: 14px;
  line-height: 1;
  margin: 3px 0 28px 14px;
  color: #2c2c2c;
  height: '200px';
}

.avatar-dropdown .pointer-arrow svg {
  color: #ffffff;
  height: 3em;
  width: 3em;
  position: absolute;
  left: 118px;
  bottom: 41px;
}

@media (max-width: 991px) {
  .dropdown-toggle.nav-link {
    margin-bottom: 5px;
  }
  .notification-nav-sm {
    border-radius: 50%;
    width: unset;
    border-top: 1px solid #fafbff;
    border-radius: unset;
  }
  .top-navbar .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
  }
  .top-navbar .margin-auto-class {
    margin-left: unset;
  }
  .top-navbar .margin-auto-class .profile-link {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .avatar-dropdown-container {
    display: none;
  }
  .top-navbar .navbar-nav .nav-link {
    padding-left: 10px;
    padding-bottom: 45px;
  }
  .not-icon-container {
    padding-right: 15px;
  }
  .navbar-nav .dashboard-link.nav-link {
    padding-right: 4rem;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .navbar-nav .programs-link.nav-link {
    padding-right: 4.5rem;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .margin-auto-class {
    margin-left: unset;
    width: 100%;
    display: flex;
    align-items: baseline;
  }
  .logout-icon svg {
    margin-bottom: 5px;
  }
}

.margin-auto-class {
  margin-left: auto;
}

.collapse:not(.show) {
  display: none;
}

.avatar-dropdown-container .coach-icon-container svg {
  height: 18px;
  width: 18px;
}

.white-notification-dropdown-container .dropdown-item .nav-link.active {
  border-bottom: none;
  line-height: 1.86;
}

.white-notification-dropdown-container .dropdown-item .nav-link {
  line-height: 1.86;
}

.white-notification-dropdown-container .dropdown-item.active {
  text-decoration: none;
  background-color: #ffffff;
}

.notification-dropdown .notification-container {
  padding-top: 0;
}

.notification-pointer-arrow .dropdown-item:focus,
.notification-pointer-arrow .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f06d21;
}

.top-navbar .navbar-nav .notification-dropdown-container .nav-link {
  line-height: unset;
  padding-bottom: 55px;
}

.dropdown-menu-arrow {
  top: -15px;
  left: 94.5%;
  width: 0;
  height: 0;
  position: relative;
}
.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 1em 1em;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}
.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #f06d21;
}
.dropdown-menu-arrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: #f06d21;
}
