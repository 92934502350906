.nav-accordion-row {
  width: 100%;
}

.nav-accordion-row .card {
  background-color: #f5f5f5;
  margin-right: -22.5px;
  border-top: solid 0.5px #e3e3e3;
}

.nav-accordion-row .card-header {
  background-color: #f5f5f5 !important;
}

.nav-accordion-row {
  padding-top: 12px;
}

@media (min-width: 992px) {
  .nav-accordion-row {
    display: none;
  }
}

.notification-dropdown-container {
  padding-bottom: 10px;
}

.nav-accordion-row .card-header {
  padding-left: 70px;
  padding-bottom: 0;
  background-color: #f5f5f5;
}

.nav-accordion-row .card-body {
  padding-top: 0;
}

.nav-accordion-row .col {
  padding: 0;
}

.notification-middle-text-container {
  white-space: nowrap;
}

.notification-dd-icon-container {
  padding-left: 20px;
}

.notification-dd-icon-container svg {
  padding-left: 1px;
}

.notification-accordion-icon-container .icon div {
  float: right;
  padding-bottom: 1.5em;
}

@media (max-width: 450px) {
  div.notification-right-text-container.col {
    padding-left: 100px;
  }
  .nav-accordion-row .card-body {
    padding-left: 10px;
  }
  .notification-right-text-container {
    white-space: nowrap;
  }
}

.notification-dropdown-row-container {
  padding-bottom: 15px;
  padding-top: 5px;
  padding-left: 5px;
}

.sm-notification-icon-number {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 4px 0;
  background-color: #f06d21;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 95px;
  top: 10px;
}

.nav-accordion-col {
  padding-left: 0;
  padding-right: 0;
}

.nav-accordion-row .card-header {
  background-color: #ffffff;
}
