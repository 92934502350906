.toast--main {
  position: fixed;
  top: 30px;
  right: 30px;
}

.toast__indicator {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
}

.toast__indicator--success {
  background: #00b750;
}

.toast__indicator--info {
  background: #3f6bee;
}

.toast__indicator--warning {
  background: yellow;
}

.toast__indicator--error {
  background: red;
}
