body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/**  Colors **/

:root {
  --primary-color: #8a51f6;
  --grey-color: #bcbec0;
  --light-grey-color: #F5F1FE;
  --focus-color: #5fe7cc;
  --background-color: #FAF9FB;
  --error-color: #D2414B;
  --background-color-secondary: #F5F1FE;

  --secondary-btn-color: #533194;
  --purple-500: #682db3;
  --grey-100: #EEEFF1;
  --grey-700: #676870;
  --grey-500: #CDCED6;
}

html {
  background-color: var(--background-color) !important;
}

/** Primary Button **/

.btn-primary {
  background-color: var(--primary-color);
  border-radius: 6px;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: var(--secondary-btn-color);
}

.btn-primary:focus {
  border-width: 5px;

  outline: 1px solid black;
  outline-offset: 5px;
  color: #ffffff;
}

.disabled-link {
  pointer-events: none;
}