.members-status-container {
  padding: 2%;
}

.members-status-container input.form-control {
  border-right: 1px solid #ced4da;
}

.members-status-container .form-control:focus {
  border-color: none;
  box-shadow: none;
}

.members-status-title {
  font-size: 42px;
  color: #2c2c2c;
  padding-bottom: 10px;
}

.members-status-description-container {
  padding-bottom: 10px;
}

.members-status-description {
  font-size: 16px;
  line-height: 1.63;
  color: #2c2c2c;
  margin-left: 5px;
  justify-content: left;
}

.members-status-title-container {
  padding-left: 30px;
  padding-bottom: 30px;
}

.members-status-form-container {
  background-color: #ffffff;
  margin-left: 15px;
  padding: 1.5%;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
}

.members-status-title-container span {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
  color: #2c2c2c;
}

.coach-dnd-container {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.coach-dnd-container .DropZone {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: dashed 2px #bcbec0;
  background-color: #f9fcfe;
  padding: 0.5em;
  padding-top: 3em;
}

.DropZone:focus {
  border-color: transparent;
}

.members-status--text__error {
  font-size: 10px;
  color: red;
  font-weight: lighter;
  margin: -10px 0 0 0;
  padding: 0;
}

.preferred-img-siz-text {
  font-size: 12px;
  color: #757575;
}

.members-status-horizontal-line {
  background-color: #e3e3e3;
  height: 1px;
  width: 100%;
}

.members-status-horizontal-line-container {
  padding-top: 1.5%;
  padding-bottom: 2.5%;
}
.members-status-icon-circle-container {
  margin-top: -3%;
  padding: 10px 30px;
}

.members-status-icon-circle-container .icon-circle {
  background-color: #d5d1fb;
  width: 62px;
  height: 62px;
}

.current-coach-container {
  margin-left: 15;
  background-color: #ffffff;
  padding: 2.5%;
  white-space: nowrap;
  border-radius: 6px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
}

.current-coach-container button {
  padding: 0;
  height: 2.75em;
}

.current-coach-title {

  font-size: 22px;
  font-weight: 600;
  color: #2c2c2c;
  padding-left: 45px;
}

.current-coach-row {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.info-coach-added-container {
  display: flex;
  flex-direction: column;
}

.current-coaches-bold-titles {

  font-size: 18px;
  font-weight: bold;
  line-height: 0.89;
  color: #2c2c2c;
  padding-bottom: 5%;
}

.coach-name-styles {

  font-size: 16px;
  font-weight: 600;
  line-height: 2.75;
  color: var(--primary-color);
  display: flex;
  align-items: center;
}

.coach-date-styles {

  font-size: 16px;
  line-height: 2.75;
  color: #393f49;
}

.date-added-container {
  text-align: center;
}
.cancel-container {
  display: flex;
  align-items: center;
}

.cancel-container a {

  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: var(--primary-color);
}

.view-coaches-btn-container {
  padding-top: 5%;
}

.current-coach-container .view-coaches-btn-container button {
  width: 100%;
  border-radius: 6px;
  background-color: #e3edff;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--primary-color);
}

.members-status-btn-container .add-new-coach-btn {
  background-color: var(--primary-color);

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #2c2c2c;
}

.members-status-btn-container .cancel-new-coach-btn {
  background-color: #ffffff;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--primary-color);
}

.members-status-btn-container .btn:not(:disabled):not(.disabled):active {
  background-color: var(--primary-color);
  color: #2c2c2c;
}

.current-coach-container
  .view-coaches-btn-container
  .btn:not(:disabled):not(.disabled):active {
  background-color: #e3edff;
}

.current-coach-container
  .view-coaches-btn-container
  .btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active,
.show > .btn.dropdown-toggle {
  background-color: var(--primary-color) !important; 
  color: var(--primary-color);
  border-color: none;
  box-shadow: none;
}

.current-coach-container .view-coaches-btn-container button:hover {
  background-color: #e3edff;
  color: var(--primary-color);
}

.current-coach-container .btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.current-coach-container .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.current-coach-container .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  border: none;
}

.current-coach-container
  .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .members-status-form-container {
    margin-right: 15px;
    margin-bottom: 30px;
  }
  .current-coach-container {
    margin-left: 15;
    margin-right: 15px;
  }
  .current-coach-row {
    padding-bottom: 20px;
  }
}

.dropbox-icon {
  position: relative;
  bottom: 145px;
  left: 80px;
  z-index: 0;
}


.icon-circle {
  border-radius: 50%;
  width: 62px;
  height: 62px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.button-container {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
}


.react-bootstrap-table .assign-coach {
  background-color: #e3edff;
  height: 40px;
  border: none;
  border-radius: 6px;
  color: var(--primary-color);
}

.react-bootstrap-table .assign-coach .assign-coach-option {
  background-color: red;
}

.admin-table-name {
  position: relative;
  top: 8px;
  font-weight: 600;
}
.reset-expansion-style .expanded-row {
  padding-bottom: 1em;
}

.react-bootstrap-table .row-expansion-style {
  padding: 0;
  padding-bottom: 1em;
  border-top: solid 1px #e3e3e3;
}

.react-bootstrap-table th {
  white-space: nowrap;
}

.react-bootstrap-table th:focus {
  outline: none;
}

.react-bootstrap-table tbody tr {
  background-color: #ffffff;
  white-space: nowrap;
  border-right: 6px solid #f6f6f6;
  border-left: 5px solid #f6f6f6;
}

.table-bordered td,
.table-bordered th {
  border: none;
}

.members-row {
  background-color: #3f6bee;
  display: flex;
  align-items: center;
  min-height: 7em;
  padding-bottom: 2px;
  margin-bottom: 1em;
  flex-direction: row;
  justify-content: space-around;
}

.members-col {
  padding-left: 4%;
}

.members-row span {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}

.members-row span span {
  font-weight: normal;
}

.search-label {
  min-width: 100%;
  width: 100%;
}

.table-row {
  padding: 0.5% 2% 0 2%;
}

.table-pag-size .btn-secondary {
  display: none;
}

@media (max-width: 992px) {
  .admin-dashboard .search {
    padding-left: 3em;
    padding-right: 2em;
  }
}

.expanded-member-details {
  padding-left: 3em;
  padding-right: 3em;
}

.react-bootstrap-table .expand-cell:first-child {
  border-top: 15px solid #FAF9FB;
  box-shadow: inset 0 8px 6px -10px #ccc, inset 0 -8px 6px -10px #ccc;
}

.table-dropdown-arrow-flip,
.table-dropdown-arrow {
  background: none;
  border: none;
}

.table-dropdown-arrow-flip svg,
.table-dropdown-arrow svg {
  position: relative;
  top: 8px;
}

.table-dropdown-arrow-flip svg {
  transform: rotate(180deg);
}

.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table .sortable svg {
  fill: #bcbec0;
}

.react-bootstrap-table .row-expansion-style {
  padding: 0;
}

#dropdown-coach-button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: #fafbff;
  border: none;
}

.react-bootstrap-table .dropdown-toggle:after {
  content: "";
}

.react-bootstrap-table .coach-btn .dropdown-item {
  font-size: 14px;
  line-height: 2.86;
  color: #2c2c2c;
  font-weight: normal;
}

.react-bootstrap-table .coach-btn .select-coach {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #2c2c2c;
}

.react-bootstrap-table a.coach-name.selected.dropdown-item {
  font-weight: 600;
}

.admin-dashboard .dropdown {
  border-radius: 8px;
}

#dropdown-more {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

#dropdown-more:hover {
  background-color: transparent;
  border: none;
}

.more-div:hover,
.more-dropdown:hover,
.more-dropdown-toggle:hover {
  border: none;
  color: var(--primary-color);
}

.more-div,
.more-dropdown,
.more-dropdown-toggle {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.more-div:hover,
.more-dropdown:hover,
.more-dropdown-toggle:hover {
  border: none;
  color: var(--primary-color);
}

.react-bootstrap-table .more-icon {
  padding-right: 8px;
}

.react-bootstrap-table .update-icon {
  padding-right: 8px;
  padding-left: 8px;
}

.react-bootstrap-table .update-icon:hover,
.react-bootstrap-table .update-text:hover {
  cursor: pointer;
}

.admin-number-scale-red {
  font-size: 16px;
  padding-right: 5px;
  color: #FF0B16;
  position: relative;
  top: 6px;
}

.admin-number-scale-black {
  font-size: 16px;
  padding-right: 5px;
  color: #2c2c2c;
  position: relative;
  top: 6px;
}

.admin-dates-red {
  font-size: 16px;
  color: #757575;
}

.admin-dates-black {
  font-size: 16px;
  color: #2c2c2c;
}

.react-bootstrap-table .assign-coach {
  background-color: #e3edff;
  height: 40px;
  border: none;
  border-radius: 6px;
  color: var(--primary-color);
}

.react-bootstrap-table .assign-coach .assign-coach-option {
  background-color: red;
}

.admin-table-name {
  position: relative;
  top: 8px;
  font-weight: 600;
}

.admin-table-employer {
  position: relative;
  top: 8px;
}

.admin-table-currentProgram {
  position: relative;
  top: 8px;
}

.admin-table-currentWeek {
  position: relative;
  top: 8px;
}

.react-bootstrap-table .update-text {
  color: var(--primary-color);
  font-weight: 600;
}

.expanded-member-details .exp-col-1 {
  margin-left: 1.25em;
  padding-top: 1.5em;
}

.expanded-member-details .header-main {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #2c2c2c;
  padding-bottom: 0.75em;
}

.expanded-member-details .member-name {
  margin-bottom: 2em;
}

.expanded-member-details .headers-grey {
  font-size: 16px;
  color: #757575;
  padding-bottom: 0.5em;
}

.expanded-member-details .member-email-phone {
  padding-top: 2em;
}

.expand-number-scale-red {
  padding-right: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #2dcd7a;
}

.expand-number-scale-black {
  padding-right: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #2c2c2c;
}

.pain-grey-box-container {
}

.pain-satis-grey-bg {
  padding-left: 2em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.pain-left-satis-grey-bg {
  padding-left: 2em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-right: 15px;
}

.satisfaction-bg {
  border-left: black;
}

.expanded-member-details .criteria-text {
  font-size: 16px;
  font-weight: 600;
  color: #2c2c2c;
  padding-bottom: 0.5em;
}

.expanded-member-details .criteria-text-blue {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  padding-bottom: 0.5em;
}

.expanded-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expanded-member-details .more-actions {
  font-size: 16px;
  line-height: 1.5;
  color: #2c2c2c;
  padding-bottom: 0.5em;
  padding-top: 1em;
  white-space: pre-wrap;
}

.coach-btn .dropdown-menu.show {
  overflow: hidden;
  min-height: 400px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.44);
}

.coach-btn .dropdown-toggle:hover {
  color: var(--primary-color);
}

.coach-btn .dropdown-toggle:active {
  color: var(--primary-color);
}

.more-text {
  color: var(--primary-color);
}

.more-text:hover {
  color: var(--primary-color);
}

.assign-text {
  padding-left: 0.5em;
}

.expanded-member-details .expand-button {
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.expanded-member-details .view-profile-col a {
  text-decoration: none;
}

.pain-grey-box-container {
  padding-right: 0;
}

.satisfaction-grey-box-container {
  padding-left: 0;
}

/* Pagination */

.react-bootstrap-table-pagination {
  margin-bottom: 2em;
  margin-left: 37%;
}

.react-bootstrap-table-pagination .table-pag-size .btn:first-child {
  display: none;
}

.react-bootstrap-table-pagination .table-pag-size .btn:nth-child(2) {
  max-width: 18em;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.react-bootstrap-table-pagination-list {
  display: none;
}

.coach-btn.form-group {
  margin-bottom: 0;
}

select#coachForm.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary-color);
  background-color: #e3edff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary-color);
  vertical-align: middle;
  background-color: #e3edff;
  border: none;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#coachForm.form-control:focus {
  border-color: transparent;
  outline: none;
  box-shadow: none;
}

.table-scroll {
  overflow: auto;
  display: block;
  table-layout: auto;
}


.search-bar {
  width: 50%;
}