.solid-btn {
  min-width: 100%;
  border-radius: 20px;
  height: 50px;
  color: var(--Basic-1-BG, #fff);
  text-align: center;

  /* Global/Button */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.15px;
}

.btn-primary {
  background-color: #8a51f6;
  border-radius: 20px;
  border: none;

  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

.btn-primary:active {
  background-color: #8a51f6;
  border-radius: 20px;

  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #ffffff !important;
}

.btn-primary:hover {
  background-color: rgb(138, 81, 246);
}

.solid-btn-icon-right {
  position: absolute;
  right: 30px;
}

.solid-btn-icon-right {
  position: absolute;
  right: 30px;
  top: 45%;
  transform: translateY(-50%);
}
