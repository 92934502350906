@media (max-width: 770px) {
  .title-row-intake {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-left: 0;
  }
}

.title-row-intake {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.intake-completed {
  height: 100%;
  max-width: 600px;
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.info {
  font-size: 18px;
  color: #2c2c2c;
}

.check-circle-container {
  color: #00b750;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.check-circle-container svg {
  width: 90px;
  height: 90px;
}
