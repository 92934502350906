//Form input text
.form-control {
  border-color: var(--grey-color) !important;
}
.form-control:focus {
  border-color: var(--focus-color) !important;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.33) !important;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
