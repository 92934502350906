.coach__more-icon {
  position: relative;
  top: 8px;
  display: flex;
  justify-content: center;
}

.coach__more-icon:hover {
  border-radius: 100%;
  cursor: pointer;
}

.coach-list__text--clicked:hover {
  cursor: pointer;
  color: #3f6bee;
}

.coach-table__table--un-styled {
  background: white;
  border: 0;
  max-height: 390px;
  overflow-y: auto;
  overflow-x: hidden;
}

.coach-table__table-body--un-styled {
  background: transparent;
  border: 0;
  box-shadow: 0 0 0 0;
}
