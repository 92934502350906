.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

.breadcrumb-item:before {
  display: none;
}

.breadcrumb-item:after {
  display: none;
}

.bc-caret {
  margin-left: 8px;
  margin-right: 11px;
  letter-spacing: -4px;
}

.breadcrumb-item.active {
  color: #2c2c2c;
  margin-left: -6px;
}

.caret-rt {
  margin-left: -11px;
}

.breadcrumb svg {
  padding-bottom: 2px;
}
