/** Fonts **/

@font-face {
  font-family: "Bitter";
  src: local("Bitter"), url("./fonts/Bitter/Bitter-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Bitter";
  font-weight: 500;
  src: local("Bitter"), url("./fonts/Bitter/Bitter-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: local("Inter"), url("./fonts/Inter/Inter-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: local("Inter"), url("./fonts/Inter/Inter-Regular.ttf") format("opentype");
}


@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: local("Inter"), url("./fonts/Inter/Inter-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: local("Inter"), url("./fonts/Inter/Inter-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: local("Inter"), url("./fonts/Inter/Inter-Bold.ttf") format("opentype");
}

** {
  font-family: Inter, Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

html {
  height: 100%;
}

body {
  background-color: var(--background-color) !important;
  height: 100%;
  font-family: Inter, Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.button:active,
button:active,
.button:focus,
button:focus,
.button:hover,
button:hover {
  border: none;
  outline: none;
}

p {
  text-align: left;
  margin-bottom: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  background-color: var(--background-color);
}


.modal-header {
  border: none !important;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 20px !important;
  }
}