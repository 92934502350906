.input-group-text {
  background-color: #ffffff;
  border-left: none;
  border-color: #ced4da;
}

input.form-control {
  height: 70px;
  border-radius: 16px;
}

.form-floating {
  position: relative;
  color: #6c757d;
  font-family: "Inter";
}

.form-floating label {
  position: absolute;
  top: 1.5em;
  left: 1rem;
  padding: 0 0.25rem;
  transition: all 0.2s;
  pointer-events: none;
  font-size: "8px";
}
.form-floating input {
  padding-left: "36px";
  padding: 1rem 3.5rem 0 1.25rem;
  margin-top: 1.5rem; /* Add margin to move input lower */
}

/* Hide placeholder when not focused */
.form-floating input::placeholder {
  color: transparent;
  transition: all 0.2s;
}
/* Show placeholder when focused */
.form-floating input:focus::placeholder {
  color: #6c757d; /* Placeholder color */
  transition: all 0.2s;
}

.form-floating input:focus + label,
.form-floating input:not(:placeholder-shown) + label {
  transform: translateY(-0.5rem);
  color: #2c2c2c; /* Placeholder color */
  font-size: 0.75rem;
}
